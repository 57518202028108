import "./Discount.scss";
import React, { useEffect, useState } from "react";

import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import * as formats from "../../utils/FormatingFunctions";
import qs from "qs";
import * as moment from "moment";
import { createVoucher } from "../../features/finance/financeThunk";

import StatisticWidget from "../../components/shared-components/StatisticWidget";
import {
  Table,
  Skeleton,
  Space,
  Button,
  Tooltip,
  Select,
  Spin,
  Empty,
  Form,
  Input,
  Upload,
  DatePicker,
  Modal,
  Drawer,
  message,
} from "antd";
// import { getAllVouchers } from "../../features/finance/financeThunk";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
const { Option } = Select;
const { Dragger } = Upload;
const API_URL = process.env.REACT_APP_BASEURL;

const Discounts = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [editingAd, setEditingAd] = useState(null);
  const [edited, setEdited] = useState(null);
  console.log(editingAd);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const { vouchers, financeLoader } = useSelector((state) => state.finance);
  const [tableParams, setTableParams] = useState({
    pagination: {
      page: 1,
    },
  });

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchTerm(value.trim()); // Trim leading/trailing spaces
    if (!value) {
      // Check if search term is empty
      fetchData(); // Call fetchData to fetch initial data
    } else {
      searchData(value); // Call searchData for filtering
    }
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const handleEdit = (ad) => {
    setEditingAd(ad);
  };

  const handleOk = () => {
    setEditingAd(null);
  };

  const handleCancel = () => {
    setEditingAd(null);
  };
  const handleEndDateChange = (date, dateString) => {
    setEndDate(moment(dateString, "YYYY-MM-DD")); // Store the selected end date without time
  };

  const handleStartDateChange = (date, dateString) => {
    setStartDate(moment(dateString, "YYYY-MM-DD")); // Store the selected start date without time
  };
  const handleDateChange = (date, dateString) => {
    setStartDate(moment(dateString, "YYYY-MM-DD")); // Store the selected start date without time
  };
  const getParams = (params) => ({
    page: params.pagination.page,
  });
  const onFinish = async (values) => {
    console.log("Received values:", values);

    // Validate and set the start date
    if (startDate) {
      values.startDate = startDate.startOf("day").format("YYYY-MM-DDTHH:mm:ss");
    }

    // Validate and set the end date
    if (endDate) {
      values.endDate = endDate.startOf("day").format("YYYY-MM-DDTHH:mm:ss");
    }

    // Filter out undefined or empty values
    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(
        ([key, value]) =>
          value !== undefined &&
          value !== null &&
          value !== "" &&
          (Array.isArray(value) ? value.length > 0 : true)
      )
    );

    // Create a new FormData object
    const formData = new FormData();

    // Append each filtered field to the FormData object
    for (const key in filteredValues) {
      if (key === "includedAirlines" || key === "excludedAirlines") {
        formData.append(key, filteredValues[key]);
      } else {
        formData.append(key, filteredValues[key]);
      }
    }

    // Additional validations and restrictions
    if (
      filteredValues.productType === "FLIGHT" &&
      filteredValues.includedAirlines?.length === 0
    ) {
      message.error("You have to add included airlines.");
      return;
    }
    if (
      filteredValues.discountPercentage !== undefined &&
      filteredValues.discountAmount !== undefined
    ) {
      message.error(
        "Voucher cannot contain both discountPercentage and discountAmount."
      );
      return;
    }
    if (
      filteredValues.discountPercentage === undefined &&
      filteredValues.discountAmount === undefined
    ) {
      message.error(
        "You have to use either discountPercentage or discountAmount."
      );
      return;
    }

    if (
      filteredValues.currency === "USD" &&
      filteredValues.discountCapNGN !== undefined
    ) {
      message.error(
        "When currency is set to USD, discountCapNGN cannot be used."
      );
      return;
    }

    if (
      filteredValues.currency === "NGN" &&
      filteredValues.discountCapUSD !== undefined
    ) {
      message.error(
        "When currency is set to NGN, discountCapUSD cannot be used."
      );
      return;
    }

    if (
      filteredValues.discountAmount !== undefined &&
      filteredValues.currency !== "USD" &&
      filteredValues.currency !== "NGN"
    ) {
      message.error(
        "When discountAmount is set, currency must be either USD or NGN."
      );
      return;
    }

    if (
      filteredValues.stopValue !== undefined &&
      filteredValues.currency !== "USD" &&
      filteredValues.currency !== "NGN"
    ) {
      message.error(
        "When stopValue is set, currency must be either USD or NGN."
      );
      return;
    }

    // Dispatch the action to create a new voucher with the form data
    dispatch(createVoucher(formData));
  };

  const searchData = (value) => {
    setLoading(true);
    fetch(`${API_URL}/payment/vouchers/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any other headers if needed
      },
      credentials: "include",
      body: JSON.stringify({ searchText: value }), // Include the search term in the body
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("Search data:", data);
        setData(data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.metadata.totalVouchers,
            pageSize: 20,
          },
        });
      })
      .catch((error) => {
        console.error("Error while searching:", error);
        setLoading(false); // Make sure to stop loading state in case of an error
      });
  };

  const fetchData = () => {
    setLoading(true);
    fetch(
      `${API_URL}/payment/vouchers?${qs.stringify(getParams(tableParams))}`,
      {
        headers: {
          // Add your headers here
          "Content-Type": "application/json", // Example of setting Content-Type header
        },
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.metadata.totalVouchers,
            pageSize: 20,
          },
        });
      });
  };

  const deleteVoucherByCode = async (code) => {
    try {
      const response = await fetch(`${API_URL}/payment/vouchers/${code}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          // Include any other headers required for authentication, etc.
        },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`Failed to delete voucher: ${response.statusText}`);
      }

      message.success(`Voucher with code ${code} deleted successfully.`);
    } catch (error) {
      message.error(`Failed to delete voucher with code ${code}:`, error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
    tableParams.pagination?.page,
  ]);

  const handleTableChange = (pagination, filters, sorter) => {
    // Update pagination parameters correctly
    const newTableParams = {
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total: data.metadata.totalVouchers,
        page: pagination.current,
        pageSize: pagination.pageSize,
      },
    };
    setTableParams(newTableParams);
  };

  useEffect(() => {
    setIData(vouchers?.vouchers);
  }, [vouchers]);

  useEffect(() => {
    fetchData();
  }, []);

  const [idata, setIData] = useState(vouchers?.vouchers);

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Product Type",
      dataIndex: "productType",
      key: "productType",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Start Date",
      dataIndex: "validDate",
      key: "startDate",
      render: (validDate) => (
        <span>{new Date(validDate.from).toDateString()}</span>
      ),
    },
    {
      title: "End Date",
      dataIndex: "validDate",
      key: "endDate",
      render: (validDate) => (
        <span>{new Date(validDate.to).toDateString()}</span>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discountAmount",
      render: (discount) => (
        <span>
          {discount?.percentage
            ? `${discount?.percentage}% capped at ${discount?.cap?.USD} in USD / ${discount?.cap?.NGN} in NGN`
            : `${discount?.cap?.USD} USD / ${discount?.cap?.NGN} NGN`}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            onClick={() => {
              deleteVoucherByCode(record.code);
            }}
          >
            Delete
          </Button>
          <Button disabled onClick={() => {}}>
            Modify
            <Tooltip title="This feature is not available yet" />
          </Button>
        </Space>
      ),
    },
  ];
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <div style={{ width: 390 }} className=" p-2">
            <StatisticWidget
              title="Total Vouchers"
              value={`${data?.metadata?.totalVouchers}`}
              status={8.8}
              subtitle="Past 30 days"
              isLoading={financeLoader}
            />
          </div>
        </div>
        <div className="charts"></div>
        <div className="listContainer">
          <section style={{ marginBottom: "40px" }}>
            <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
              Create New Voucher
            </Button>
          </section>
          <div className="listTitle">Vouchers</div>
          <Drawer
            title="Create new Voucher"
            width={720}
            onClose={onClose}
            open={open}
            styles={{
              body: {
                paddingBottom: 80,
              },
            }}
          >
            <Form
              onFinish={onFinish}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 10 }}
            >
              <Form.Item
                label="Code"
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Please specify the voucher code",
                  },
                  {
                    pattern: /^[A-Z0-9]{3,6}$/,
                    message:
                      "Code should be 3-6 uppercase alphanumeric characters",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Product Type"
                name="productType"
                rules={[
                  {
                    required: true,
                    message: "Please specify the product type",
                  },
                ]}
              >
                <Select placeholder="Select a product type">
                  <Option value="*">All Products</Option>
                  <Option value="FLIGHT">Flight Bookings</Option>
                  <Option value="INSURANCE">Insurance Bookings</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Require Authentication"
                name="requireAuth"
                rules={[
                  {
                    required: true,
                    message: "Please specify if authentication is required",
                  },
                ]}
              >
                <Select placeholder="Select an option">
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: "Please specify the currency",
                  },
                ]}
              >
                <Select placeholder="Select a currency">
                  <Option value="*">All Currencies</Option>
                  <Option value="USD">USD</Option>
                  <Option value="NGN">NGN</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Start Date"
                name="startDate"
                rules={[
                  {
                    required: true,
                    message: "Please specify the start date",
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DDTHH:mm:ss"
                  showTime
                  onChange={handleStartDateChange}
                />
              </Form.Item>

              <Form.Item
                label="End Date"
                name="endDate"
                rules={[
                  {
                    required: true,
                    message: "Please specify the end date",
                  },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DDTHH:mm:ss"
                  showTime
                  onChange={handleEndDateChange}
                />
              </Form.Item>

              <Form.Item label="Discount Percentage" name="discountPercentage">
                <Input
                  type="number"
                  min={0}
                  max={100}
                  placeholder="Enter percentage"
                  value={null}
                />
              </Form.Item>

              <Form.Item label="Discount Amount" name="discountAmount">
                <Input value={null} type="number" placeholder="Enter amount" />
              </Form.Item>

              <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.currency !== currentValues.currency
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("currency") === "NGN" ? (
                    <>
                      <Form.Item label="Discount Cap NGN" name="discountCapNGN">
                        <Input
                          type="number"
                          placeholder="Enter capped amount in NGN"
                        />
                      </Form.Item>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Form.Item label="Discount Cap USD" name="discountCapUSD">
                        <Input
                          value={null}
                          type="number"
                          placeholder="Enter capped amount in USD"
                        />
                      </Form.Item>
                    </>
                  )
                }
              </Form.Item>

              <Form.Item label="Stop Value" name="stopValue">
                <Input type="number" placeholder="Enter stop value" />
              </Form.Item>
              <Form.Item
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.productType !== currentValues.productType
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("productType") === "FLIGHT" ? (
                    <>
                      <Form.Item
                        label="Included Airlines"
                        name="includedAirlines"
                      >
                        <Select
                          mode="multiple"
                          placeholder="Select airlines"
                          options={[
                            { value: "*", label: "All Airlines" },
                            { value: "ET", label: "Ethiopian Airlines" },
                            { value: "KL", label: "KLM Royal Dutch Airlines" },
                            { value: "SA", label: "South African Airways" },
                            { value: "AT", label: "Royal Air MAROC" },
                            { value: "QR", label: "Qatar Airways" },
                            { value: "LH", label: "Lufthansa" },
                            { value: "WB", label: "RwandAir" },
                            { value: "TK", label: "Turkish Airlines" },
                            { value: "HF", label: "AIR COTE D’IVOIRE" },
                            { value: "AW", label: "Africa World Airlines" },
                            { value: "EY", label: "Etihad Airways" },
                            { value: "KQ", label: "Kenya Airways" },
                            { value: "EK", label: "Emirates" },
                            { value: "VS", label: "Virgin Atlantic" },
                            { value: "KP", label: "ASKY Airlines" },
                            { value: "BA", label: "British Airways" },
                            { value: "SW", label: "Air Namibia" },
                            { value: "DL", label: "Delta Air Lines" },
                            { value: "MS", label: "EgyptAir" },
                            { value: "P4", label: "Air Peace" },
                            { value: "W3", label: "Arik Air" },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Excluded Airlines"
                        name="excludedAirlines"
                      >
                        <Select
                          mode="multiple"
                          placeholder="Select airlines"
                          options={[
                            { value: "*", label: "All Airlines" },
                            { value: "ET", label: "Ethiopian Airlines" },
                            { value: "KL", label: "KLM Royal Dutch Airlines" },
                            { value: "SA", label: "South African Airways" },
                            { value: "AT", label: "Royal Air MAROC" },
                            { value: "QR", label: "Qatar Airways" },
                            { value: "LH", label: "Lufthansa" },
                            { value: "WB", label: "RwandAir" },
                            { value: "TK", label: "Turkish Airlines" },
                            { value: "HF", label: "AIR COTE D’IVOIRE" },
                            { value: "AW", label: "Africa World Airlines" },
                            { value: "EY", label: "Etihad Airways" },
                            { value: "KQ", label: "Kenya Airways" },
                            { value: "EK", label: "Emirates" },
                            { value: "VS", label: "Virgin Atlantic" },
                            { value: "KP", label: "ASKY Airlines" },
                            { value: "BA", label: "British Airways" },
                            { value: "SW", label: "Air Namibia" },
                            { value: "DL", label: "Delta Air Lines" },
                            { value: "MS", label: "EgyptAir" },
                            { value: "P4", label: "Air Peace" },
                            { value: "W3", label: "Arik Air" },
                          ]}
                        />
                      </Form.Item>
                    </>
                  ) : null
                }
              </Form.Item>

              <Form.Item
                label="Singular Use"
                name="singularUse"
                rules={[
                  {
                    required: true,
                    message:
                      "Please specify if the voucher is for singular use",
                  },
                ]}
              >
                <Select placeholder="Select an option">
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Drawer>
          <input
            style={{ width: "70%", padding: "9px", marginBottom: "10px" }}
            type="text"
            value={searchTerm}
            placeholder="Search for Vouchers"
            onChange={(e) => {
              handleSearch(e);
            }}
          />
          <Table
            columns={columns}
            dataSource={data?.vouchers}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default Discounts;
