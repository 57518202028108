export const countries = [
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 114,
    Name: "Afghanistan",
    AlphaCode: "AF",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 116,
    Name: "Albania",
    AlphaCode: "AL",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 150,
    Name: "Algeria",
    AlphaCode: "DZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 113,
    Name: "Andorra",
    AlphaCode: "AD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 118,
    Name: "Angola",
    AlphaCode: "AO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 115,
    Name: "Anguilla",
    AlphaCode: "AI",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 175,
    Name: "Antigua & Barbuda",
    AlphaCode: "AG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 119,
    Name: "Argentina",
    AlphaCode: "AR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 117,
    Name: "Armenia",
    AlphaCode: "AM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 120,
    Name: "Australia",
    AlphaCode: "AU",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 2,
    Name: "Austria",
    AlphaCode: "AT",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 121,
    Name: "Azerbaijan",
    AlphaCode: "AZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 132,
    Name: "Bahamas",
    AlphaCode: "BS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 126,
    Name: "Bahrain",
    AlphaCode: "BH",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 123,
    Name: "Bangladesh",
    AlphaCode: "BD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 122,
    Name: "Barbados",
    AlphaCode: "BB",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 135,
    Name: "Belarus",
    AlphaCode: "BY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 3,
    Name: "Belgium",
    AlphaCode: "BE",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 136,
    Name: "Belize",
    AlphaCode: "BZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 128,
    Name: "Benin",
    AlphaCode: "BJ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 129,
    Name: "Bermuda",
    AlphaCode: "BM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 133,
    Name: "Bhutan",
    AlphaCode: "BT",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 176,
    Name: "Bolivia",
    AlphaCode: "BO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 177,
    Name: "Bosnia & Herzegovina",
    AlphaCode: "BA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 134,
    Name: "Botswana",
    AlphaCode: "BW",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 131,
    Name: "Brazil",
    AlphaCode: "BR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 130,
    Name: "Brunei Darussalam",
    AlphaCode: "BN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 125,
    Name: "Bulgaria",
    AlphaCode: "BG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 124,
    Name: "Burkina Faso",
    AlphaCode: "BF",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 127,
    Name: "Burundi",
    AlphaCode: "BI",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 170,
    Name: "Cambodia",
    AlphaCode: "KH",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 140,
    Name: "Cameroon",
    AlphaCode: "CM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 2,
      Name: "US_Canada_Japan",
      Description: "Worldwide including all Schengen states",
    },
    CountryId: 4,
    Name: "Canada",
    AlphaCode: "CA",
    RegionId: 2,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 145,
    Name: "Cape Verde",
    AlphaCode: "CV",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 172,
    Name: "Cayman Islands",
    AlphaCode: "KY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 137,
    Name: "Central African Republic",
    AlphaCode: "CF",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 174,
    Name: "Chad",
    AlphaCode: "TD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 139,
    Name: "Chile",
    AlphaCode: "CL",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 141,
    Name: "China",
    AlphaCode: "CN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 142,
    Name: "Colombia",
    AlphaCode: "CO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 171,
    Name: "Comoros",
    AlphaCode: "KM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 138,
    Name: "Congo",
    AlphaCode: "CG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 143,
    Name: "Costa Rica",
    AlphaCode: "CR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 168,
    Name: "Croatia",
    AlphaCode: "HR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 144,
    Name: "Cuba",
    AlphaCode: "CU",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 146,
    Name: "Cyprus",
    AlphaCode: "CY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 6,
    Name: "Czech Republic",
    AlphaCode: "CZ",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 178,
    Name: "Democratic Republic of the Congo",
    AlphaCode: "CD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 8,
    Name: "Denmark",
    AlphaCode: "DK",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 147,
    Name: "Djibouti",
    AlphaCode: "DJ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 148,
    Name: "Dominica",
    AlphaCode: "DM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 149,
    Name: "Dominican Republic",
    AlphaCode: "DO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 151,
    Name: "Ecuador",
    AlphaCode: "EC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 152,
    Name: "Egypt",
    AlphaCode: "EG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 173,
    Name: "El Salvador",
    AlphaCode: "SV",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 164,
    Name: "Equatorial Guinea",
    AlphaCode: "GQ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 153,
    Name: "Eritrea",
    AlphaCode: "ER",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 9,
    Name: "Estonia",
    AlphaCode: "EE",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 154,
    Name: "Ethiopia",
    AlphaCode: "ET",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 155,
    Name: "Fiji",
    AlphaCode: "FJ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 11,
    Name: "Finland",
    AlphaCode: "FI",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 12,
    Name: "France",
    AlphaCode: "FR",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 159,
    Name: "French Guiana",
    AlphaCode: "GF",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 156,
    Name: "Gabon",
    AlphaCode: "GA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 161,
    Name: "Gambia",
    AlphaCode: "GM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 158,
    Name: "Georgia",
    AlphaCode: "GE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 7,
    Name: "Germany",
    AlphaCode: "DE",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 160,
    Name: "Ghana",
    AlphaCode: "GH",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 179,
    Name: "Great Britain",
    AlphaCode: "GB",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 13,
    Name: "Greece",
    AlphaCode: "GR",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 157,
    Name: "Grenada",
    AlphaCode: "GD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 163,
    Name: "Guadeloupe",
    AlphaCode: "GP",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 165,
    Name: "Guatemala",
    AlphaCode: "GT",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 162,
    Name: "Guinea",
    AlphaCode: "GN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 166,
    Name: "Guinea-Bissau",
    AlphaCode: "GW",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 167,
    Name: "Guyana",
    AlphaCode: "GY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 169,
    Name: "Haiti",
    AlphaCode: "HT",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 14,
    Name: "Honduras",
    AlphaCode: "HN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 15,
    Name: "Hungary",
    AlphaCode: "HU",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 19,
    Name: "Iceland",
    AlphaCode: "IS",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 17,
    Name: "India",
    AlphaCode: "IN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 16,
    Name: "Indonesia",
    AlphaCode: "ID",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 180,
    Name: "Iran",
    AlphaCode: "IR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 18,
    Name: "Iraq",
    AlphaCode: "IQ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 200,
    Name: "Ireland",
    AlphaCode: "GB",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 181,
    Name: "Israel and the Occupied Territories",
    AlphaCode: "IL",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 20,
    Name: "Italy",
    AlphaCode: "IT",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 182,
    Name: "Ivory Coast (Cote d'Ivoire)",
    AlphaCode: "CI",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 21,
    Name: "Jamaica",
    AlphaCode: "JM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 2,
      Name: "US_Canada_Japan",
      Description: "Worldwide including all Schengen states",
    },
    CountryId: 23,
    Name: "Japan",
    AlphaCode: "JP",
    RegionId: 2,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 22,
    Name: "Jordan",
    AlphaCode: "JO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 27,
    Name: "Kazakhstan",
    AlphaCode: "KZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 24,
    Name: "Kenya",
    AlphaCode: "KE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 183,
    Name: "Korea, Democratic Republic of (North Korea) ",
    AlphaCode: "KP",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 184,
    Name: "Korea, Republic of (South Korea)",
    AlphaCode: "KR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 185,
    Name: "Kosovo",
    AlphaCode: "RS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 26,
    Name: "Kuwait",
    AlphaCode: "KW",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 186,
    Name: "Kyrgyz Republic (Kyrgyzstan)",
    AlphaCode: "KG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 187,
    Name: "Laos",
    AlphaCode: "LA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 36,
    Name: "Latvia",
    AlphaCode: "LV",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 28,
    Name: "Lebanon",
    AlphaCode: "LB",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 33,
    Name: "Lesotho",
    AlphaCode: "LS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 32,
    Name: "Liberia",
    AlphaCode: "LR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 37,
    Name: "Libya",
    AlphaCode: "LY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 30,
    Name: "Liechtenstein",
    AlphaCode: "LI",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 34,
    Name: "Lithuania",
    AlphaCode: "LT",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 35,
    Name: "Luxembourg",
    AlphaCode: "LU",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 42,
    Name: "Madagascar",
    AlphaCode: "MG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 51,
    Name: "Malawi",
    AlphaCode: "MW",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 53,
    Name: "Malaysia",
    AlphaCode: "MY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 50,
    Name: "Maldives",
    AlphaCode: "MV",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 43,
    Name: "Mali",
    AlphaCode: "ML",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 48,
    Name: "Malta",
    AlphaCode: "MT",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 45,
    Name: "Martinique",
    AlphaCode: "MQ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 46,
    Name: "Mauritania",
    AlphaCode: "MR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 49,
    Name: "Mauritius",
    AlphaCode: "MU",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 109,
    Name: "Mayotte",
    AlphaCode: "YT",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 52,
    Name: "Mexico",
    AlphaCode: "MX",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 40,
    Name: "Moldova, Republic of",
    AlphaCode: "MD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 39,
    Name: "Monaco",
    AlphaCode: "MC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 44,
    Name: "Mongolia",
    AlphaCode: "MN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 41,
    Name: "Montenegro",
    AlphaCode: "ME",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 47,
    Name: "Montserrat",
    AlphaCode: "MS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 38,
    Name: "Morocco",
    AlphaCode: "MA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 54,
    Name: "Mozambique",
    AlphaCode: "MZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 188,
    Name: "Myanmar/Burma",
    AlphaCode: "MM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 55,
    Name: "Namibia",
    AlphaCode: "NA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 61,
    Name: "Nepal",
    AlphaCode: "NP",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 59,
    Name: "Netherlands",
    AlphaCode: "NL",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 62,
    Name: "New Zealand",
    AlphaCode: "NZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 58,
    Name: "Nicaragua",
    AlphaCode: "NI",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "",
    },
    CountryId: 57,
    Name: "Nigeria",
    AlphaCode: "NG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 56,
    Name: "Niger",
    AlphaCode: "NE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 60,
    Name: "Norway",
    AlphaCode: "NO",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 63,
    Name: "Oman",
    AlphaCode: "OM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 189,
    Name: "Pacific Islands",
    AlphaCode: "PC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 68,
    Name: "Pakistan",
    AlphaCode: "PK",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 64,
    Name: "Panama",
    AlphaCode: "PA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 66,
    Name: "Papua New Guinea",
    AlphaCode: "PG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 72,
    Name: "Paraguay",
    AlphaCode: "PY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 65,
    Name: "Peru",
    AlphaCode: "PE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 67,
    Name: "Philippines",
    AlphaCode: "PH",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 69,
    Name: "Poland",
    AlphaCode: "PL",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 71,
    Name: "Portugal",
    AlphaCode: "PT",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 70,
    Name: "Puerto Rico",
    AlphaCode: "PR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 73,
    Name: "Qatar",
    AlphaCode: "QA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 190,
    Name: "Republic of Macedonia",
    AlphaCode: "MK",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 191,
    Name: "Reunion",
    AlphaCode: "RE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 74,
    Name: "Romania",
    AlphaCode: "RO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 76,
    Name: "Russian Federation",
    AlphaCode: "RU",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 77,
    Name: "Rwanda",
    AlphaCode: "RW",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 25,
    Name: "Saint Kitts and Nevis",
    AlphaCode: "KN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 29,
    Name: "Saint Lucia",
    AlphaCode: "LC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 192,
    Name: "Saint Vincent's & Grenadines",
    AlphaCode: "VC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 107,
    Name: "Samoa",
    AlphaCode: "WS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 91,
    Name: "Sao Tome and Principe",
    AlphaCode: "ST",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 78,
    Name: "Saudi Arabia",
    AlphaCode: "SA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 87,
    Name: "Senegal",
    AlphaCode: "SN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 75,
    Name: "Serbia",
    AlphaCode: "RS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 80,
    Name: "Seychelles",
    AlphaCode: "SC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 86,
    Name: "Sierra Leone",
    AlphaCode: "SL",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 83,
    Name: "Singapore",
    AlphaCode: "SG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 193,
    Name: "Slovak Republic (Slovakia)",
    AlphaCode: "SK",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 85,
    Name: "Slovakia",
    AlphaCode: "SK",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 84,
    Name: "Slovenia",
    AlphaCode: "SI",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 79,
    Name: "Solomon Islands",
    AlphaCode: "SB",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 88,
    Name: "Somalia",
    AlphaCode: "SO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 110,
    Name: "South Africa",
    AlphaCode: "ZA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 90,
    Name: "South Sudan",
    AlphaCode: "SS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 10,
    Name: "Spain",
    AlphaCode: "ES",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 31,
    Name: "Sri Lanka",
    AlphaCode: "LK",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 81,
    Name: "Sudan",
    AlphaCode: "SD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 89,
    Name: "Suriname",
    AlphaCode: "SR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 92,
    Name: "Swaziland",
    AlphaCode: "SZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 82,
    Name: "Sweden",
    AlphaCode: "SE",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 5,
    Name: "Switzerland",
    AlphaCode: "CH",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 194,
    Name: "Syria",
    AlphaCode: "SY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 95,
    Name: "Tajikistan",
    AlphaCode: "TJ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 195,
    Name: "Tanzania",
    AlphaCode: "TZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 94,
    Name: "Thailand",
    AlphaCode: "TH",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 196,
    Name: "Timor Leste",
    AlphaCode: "TL",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 93,
    Name: "Togo",
    AlphaCode: "TG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 197,
    Name: "Trinidad & Tobago",
    AlphaCode: "TT",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 97,
    Name: "Tunisia",
    AlphaCode: "TN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 98,
    Name: "Turkey",
    AlphaCode: "TR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 96,
    Name: "Turkmenistan",
    AlphaCode: "TM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 198,
    Name: "Turks & Caicos Islands",
    AlphaCode: "TC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 100,
    Name: "Uganda",
    AlphaCode: "UG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 99,
    Name: "Ukraine",
    AlphaCode: "UA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 1,
    Name: "United Arab Emirates",
    AlphaCode: "AE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 2,
      Name: "US_Canada_Japan",
      Description: "Worldwide including all Schengen states",
    },
    CountryId: 199,
    Name: "United States of America (USA)",
    AlphaCode: "US",
    RegionId: 2,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 101,
    Name: "Uruguay",
    AlphaCode: "UY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 102,
    Name: "Uzbekistan",
    AlphaCode: "UZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 103,
    Name: "Venezuela",
    AlphaCode: "VE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 106,
    Name: "Vietnam",
    AlphaCode: "VN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 104,
    Name: "Virgin Islands (UK)",
    AlphaCode: "VG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 105,
    Name: "Virgin Islands (US)",
    AlphaCode: "VI",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 108,
    Name: "Yemen",
    AlphaCode: "YE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 111,
    Name: "Zambia",
    AlphaCode: "ZM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 112,
    Name: "Zimbabwe",
    AlphaCode: "ZW",
    RegionId: 3,
  },
];
export const insurancecountries = [
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 114,
    Name: "Afghanistan",
    AlphaCode: "AF",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 116,
    Name: "Albania",
    AlphaCode: "AL",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 150,
    Name: "Algeria",
    AlphaCode: "DZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 113,
    Name: "Andorra",
    AlphaCode: "AD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 118,
    Name: "Angola",
    AlphaCode: "AO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 115,
    Name: "Anguilla",
    AlphaCode: "AI",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 175,
    Name: "Antigua & Barbuda",
    AlphaCode: "AG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 119,
    Name: "Argentina",
    AlphaCode: "AR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 117,
    Name: "Armenia",
    AlphaCode: "AM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 120,
    Name: "Australia",
    AlphaCode: "AU",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 2,
    Name: "Austria",
    AlphaCode: "AT",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 121,
    Name: "Azerbaijan",
    AlphaCode: "AZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 132,
    Name: "Bahamas",
    AlphaCode: "BS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 126,
    Name: "Bahrain",
    AlphaCode: "BH",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 123,
    Name: "Bangladesh",
    AlphaCode: "BD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 122,
    Name: "Barbados",
    AlphaCode: "BB",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 135,
    Name: "Belarus",
    AlphaCode: "BY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 3,
    Name: "Belgium",
    AlphaCode: "BE",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 136,
    Name: "Belize",
    AlphaCode: "BZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 128,
    Name: "Benin",
    AlphaCode: "BJ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 129,
    Name: "Bermuda",
    AlphaCode: "BM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 133,
    Name: "Bhutan",
    AlphaCode: "BT",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 176,
    Name: "Bolivia",
    AlphaCode: "BO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 177,
    Name: "Bosnia & Herzegovina",
    AlphaCode: "BA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 134,
    Name: "Botswana",
    AlphaCode: "BW",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 131,
    Name: "Brazil",
    AlphaCode: "BR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 130,
    Name: "Brunei Darussalam",
    AlphaCode: "BN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 125,
    Name: "Bulgaria",
    AlphaCode: "BG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 124,
    Name: "Burkina Faso",
    AlphaCode: "BF",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 127,
    Name: "Burundi",
    AlphaCode: "BI",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 170,
    Name: "Cambodia",
    AlphaCode: "KH",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 140,
    Name: "Cameroon",
    AlphaCode: "CM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 2,
      Name: "US_Canada_Japan",
      Description: "Worldwide including all Schengen states",
    },
    CountryId: 4,
    Name: "Canada",
    AlphaCode: "CA",
    RegionId: 2,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 145,
    Name: "Cape Verde",
    AlphaCode: "CV",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 172,
    Name: "Cayman Islands",
    AlphaCode: "KY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 137,
    Name: "Central African Republic",
    AlphaCode: "CF",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 174,
    Name: "Chad",
    AlphaCode: "TD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 139,
    Name: "Chile",
    AlphaCode: "CL",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 141,
    Name: "China",
    AlphaCode: "CN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 142,
    Name: "Colombia",
    AlphaCode: "CO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 171,
    Name: "Comoros",
    AlphaCode: "KM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 138,
    Name: "Congo",
    AlphaCode: "CG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 143,
    Name: "Costa Rica",
    AlphaCode: "CR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 168,
    Name: "Croatia",
    AlphaCode: "HR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 144,
    Name: "Cuba",
    AlphaCode: "CU",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 146,
    Name: "Cyprus",
    AlphaCode: "CY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 6,
    Name: "Czech Republic",
    AlphaCode: "CZ",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 178,
    Name: "Democratic Republic of the Congo",
    AlphaCode: "CD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 8,
    Name: "Denmark",
    AlphaCode: "DK",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 147,
    Name: "Djibouti",
    AlphaCode: "DJ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 148,
    Name: "Dominica",
    AlphaCode: "DM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 149,
    Name: "Dominican Republic",
    AlphaCode: "DO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 151,
    Name: "Ecuador",
    AlphaCode: "EC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 152,
    Name: "Egypt",
    AlphaCode: "EG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 173,
    Name: "El Salvador",
    AlphaCode: "SV",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 164,
    Name: "Equatorial Guinea",
    AlphaCode: "GQ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 153,
    Name: "Eritrea",
    AlphaCode: "ER",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 9,
    Name: "Estonia",
    AlphaCode: "EE",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 154,
    Name: "Ethiopia",
    AlphaCode: "ET",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 155,
    Name: "Fiji",
    AlphaCode: "FJ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 11,
    Name: "Finland",
    AlphaCode: "FI",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 12,
    Name: "France",
    AlphaCode: "FR",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 159,
    Name: "French Guiana",
    AlphaCode: "GF",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 156,
    Name: "Gabon",
    AlphaCode: "GA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 161,
    Name: "Gambia",
    AlphaCode: "GM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 158,
    Name: "Georgia",
    AlphaCode: "GE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 7,
    Name: "Germany",
    AlphaCode: "DE",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 160,
    Name: "Ghana",
    AlphaCode: "GH",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 179,
    Name: "Great Britain",
    AlphaCode: "GB",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 13,
    Name: "Greece",
    AlphaCode: "GR",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 157,
    Name: "Grenada",
    AlphaCode: "GD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 163,
    Name: "Guadeloupe",
    AlphaCode: "GP",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 165,
    Name: "Guatemala",
    AlphaCode: "GT",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 162,
    Name: "Guinea",
    AlphaCode: "GN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 166,
    Name: "Guinea-Bissau",
    AlphaCode: "GW",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 167,
    Name: "Guyana",
    AlphaCode: "GY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 169,
    Name: "Haiti",
    AlphaCode: "HT",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 14,
    Name: "Honduras",
    AlphaCode: "HN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 15,
    Name: "Hungary",
    AlphaCode: "HU",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 19,
    Name: "Iceland",
    AlphaCode: "IS",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 17,
    Name: "India",
    AlphaCode: "IN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 16,
    Name: "Indonesia",
    AlphaCode: "ID",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 180,
    Name: "Iran",
    AlphaCode: "IR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 18,
    Name: "Iraq",
    AlphaCode: "IQ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 200,
    Name: "Ireland",
    AlphaCode: "GB",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 181,
    Name: "Israel and the Occupied Territories",
    AlphaCode: "IL",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 20,
    Name: "Italy",
    AlphaCode: "IT",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 182,
    Name: "Ivory Coast (Cote d'Ivoire)",
    AlphaCode: "CI",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 21,
    Name: "Jamaica",
    AlphaCode: "JM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 2,
      Name: "US_Canada_Japan",
      Description: "Worldwide including all Schengen states",
    },
    CountryId: 23,
    Name: "Japan",
    AlphaCode: "JP",
    RegionId: 2,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 22,
    Name: "Jordan",
    AlphaCode: "JO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 27,
    Name: "Kazakhstan",
    AlphaCode: "KZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 24,
    Name: "Kenya",
    AlphaCode: "KE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 183,
    Name: "Korea, Democratic Republic of (North Korea) ",
    AlphaCode: "KP",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 184,
    Name: "Korea, Republic of (South Korea)",
    AlphaCode: "KR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 185,
    Name: "Kosovo",
    AlphaCode: "RS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 26,
    Name: "Kuwait",
    AlphaCode: "KW",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 186,
    Name: "Kyrgyz Republic (Kyrgyzstan)",
    AlphaCode: "KG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 187,
    Name: "Laos",
    AlphaCode: "LA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 36,
    Name: "Latvia",
    AlphaCode: "LV",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 28,
    Name: "Lebanon",
    AlphaCode: "LB",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 33,
    Name: "Lesotho",
    AlphaCode: "LS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 32,
    Name: "Liberia",
    AlphaCode: "LR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 37,
    Name: "Libya",
    AlphaCode: "LY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 30,
    Name: "Liechtenstein",
    AlphaCode: "LI",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 34,
    Name: "Lithuania",
    AlphaCode: "LT",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 35,
    Name: "Luxembourg",
    AlphaCode: "LU",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 42,
    Name: "Madagascar",
    AlphaCode: "MG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 51,
    Name: "Malawi",
    AlphaCode: "MW",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 53,
    Name: "Malaysia",
    AlphaCode: "MY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 50,
    Name: "Maldives",
    AlphaCode: "MV",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 43,
    Name: "Mali",
    AlphaCode: "ML",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 48,
    Name: "Malta",
    AlphaCode: "MT",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 45,
    Name: "Martinique",
    AlphaCode: "MQ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 46,
    Name: "Mauritania",
    AlphaCode: "MR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 49,
    Name: "Mauritius",
    AlphaCode: "MU",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 109,
    Name: "Mayotte",
    AlphaCode: "YT",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 52,
    Name: "Mexico",
    AlphaCode: "MX",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 40,
    Name: "Moldova, Republic of",
    AlphaCode: "MD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 39,
    Name: "Monaco",
    AlphaCode: "MC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 44,
    Name: "Mongolia",
    AlphaCode: "MN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 41,
    Name: "Montenegro",
    AlphaCode: "ME",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 47,
    Name: "Montserrat",
    AlphaCode: "MS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 38,
    Name: "Morocco",
    AlphaCode: "MA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 54,
    Name: "Mozambique",
    AlphaCode: "MZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 188,
    Name: "Myanmar/Burma",
    AlphaCode: "MM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 55,
    Name: "Namibia",
    AlphaCode: "NA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 61,
    Name: "Nepal",
    AlphaCode: "NP",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 59,
    Name: "Netherlands",
    AlphaCode: "NL",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 62,
    Name: "New Zealand",
    AlphaCode: "NZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 58,
    Name: "Nicaragua",
    AlphaCode: "NI",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 56,
    Name: "Niger",
    AlphaCode: "NE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 60,
    Name: "Norway",
    AlphaCode: "NO",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 63,
    Name: "Oman",
    AlphaCode: "OM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 189,
    Name: "Pacific Islands",
    AlphaCode: "PC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 68,
    Name: "Pakistan",
    AlphaCode: "PK",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 64,
    Name: "Panama",
    AlphaCode: "PA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 66,
    Name: "Papua New Guinea",
    AlphaCode: "PG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 72,
    Name: "Paraguay",
    AlphaCode: "PY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 65,
    Name: "Peru",
    AlphaCode: "PE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 67,
    Name: "Philippines",
    AlphaCode: "PH",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 69,
    Name: "Poland",
    AlphaCode: "PL",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 71,
    Name: "Portugal",
    AlphaCode: "PT",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 70,
    Name: "Puerto Rico",
    AlphaCode: "PR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 73,
    Name: "Qatar",
    AlphaCode: "QA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 190,
    Name: "Republic of Macedonia",
    AlphaCode: "MK",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 191,
    Name: "Reunion",
    AlphaCode: "RE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 74,
    Name: "Romania",
    AlphaCode: "RO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 76,
    Name: "Russian Federation",
    AlphaCode: "RU",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 77,
    Name: "Rwanda",
    AlphaCode: "RW",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 25,
    Name: "Saint Kitts and Nevis",
    AlphaCode: "KN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 29,
    Name: "Saint Lucia",
    AlphaCode: "LC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 192,
    Name: "Saint Vincent's & Grenadines",
    AlphaCode: "VC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 107,
    Name: "Samoa",
    AlphaCode: "WS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 91,
    Name: "Sao Tome and Principe",
    AlphaCode: "ST",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 78,
    Name: "Saudi Arabia",
    AlphaCode: "SA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 87,
    Name: "Senegal",
    AlphaCode: "SN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 75,
    Name: "Serbia",
    AlphaCode: "RS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 80,
    Name: "Seychelles",
    AlphaCode: "SC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 86,
    Name: "Sierra Leone",
    AlphaCode: "SL",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 83,
    Name: "Singapore",
    AlphaCode: "SG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 193,
    Name: "Slovak Republic (Slovakia)",
    AlphaCode: "SK",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 85,
    Name: "Slovakia",
    AlphaCode: "SK",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 84,
    Name: "Slovenia",
    AlphaCode: "SI",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 79,
    Name: "Solomon Islands",
    AlphaCode: "SB",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 88,
    Name: "Somalia",
    AlphaCode: "SO",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 110,
    Name: "South Africa",
    AlphaCode: "ZA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 90,
    Name: "South Sudan",
    AlphaCode: "SS",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 10,
    Name: "Spain",
    AlphaCode: "ES",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 31,
    Name: "Sri Lanka",
    AlphaCode: "LK",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 81,
    Name: "Sudan",
    AlphaCode: "SD",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 89,
    Name: "Suriname",
    AlphaCode: "SR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 92,
    Name: "Swaziland",
    AlphaCode: "SZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 82,
    Name: "Sweden",
    AlphaCode: "SE",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 1,
      Name: "Schengen",
      Description: "All Schengen States",
    },
    CountryId: 5,
    Name: "Switzerland",
    AlphaCode: "CH",
    RegionId: 1,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 194,
    Name: "Syria",
    AlphaCode: "SY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 95,
    Name: "Tajikistan",
    AlphaCode: "TJ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 195,
    Name: "Tanzania",
    AlphaCode: "TZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 94,
    Name: "Thailand",
    AlphaCode: "TH",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 196,
    Name: "Timor Leste",
    AlphaCode: "TL",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 93,
    Name: "Togo",
    AlphaCode: "TG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 197,
    Name: "Trinidad & Tobago",
    AlphaCode: "TT",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 97,
    Name: "Tunisia",
    AlphaCode: "TN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 98,
    Name: "Turkey",
    AlphaCode: "TR",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 96,
    Name: "Turkmenistan",
    AlphaCode: "TM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 198,
    Name: "Turks & Caicos Islands",
    AlphaCode: "TC",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 100,
    Name: "Uganda",
    AlphaCode: "UG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 99,
    Name: "Ukraine",
    AlphaCode: "UA",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 1,
    Name: "United Arab Emirates",
    AlphaCode: "AE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 2,
      Name: "US_Canada_Japan",
      Description: "Worldwide including all Schengen states",
    },
    CountryId: 199,
    Name: "United States of America (USA)",
    AlphaCode: "US",
    RegionId: 2,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 101,
    Name: "Uruguay",
    AlphaCode: "UY",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 102,
    Name: "Uzbekistan",
    AlphaCode: "UZ",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 103,
    Name: "Venezuela",
    AlphaCode: "VE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 106,
    Name: "Vietnam",
    AlphaCode: "VN",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 104,
    Name: "Virgin Islands (UK)",
    AlphaCode: "VG",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 105,
    Name: "Virgin Islands (US)",
    AlphaCode: "VI",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 108,
    Name: "Yemen",
    AlphaCode: "YE",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 111,
    Name: "Zambia",
    AlphaCode: "ZM",
    RegionId: 3,
  },
  {
    Region: {
      RegionId: 3,
      Name: "Others",
      Description: "Worldwide excluding USA, Canada & Japan",
    },
    CountryId: 112,
    Name: "Zimbabwe",
    AlphaCode: "ZW",
    RegionId: 3,
  },
];
